<template>
  <b-overlay :show="overlay" class="row">
    <div class="col-12 col-lg-4" v-if="stats.devices != undefined">
      <statistic-card-horizontal
        icon="HardDriveIcon"
        :statistic="stats.devices"
        statistic-title="عدد الاجهزة"
        color="success"
      />
    </div>
    <div class="col-12 col-lg-4" v-if="stats.devices != undefined">
      <statistic-card-horizontal
        icon="UsersIcon"
        :statistic="stats.users"
        statistic-title="عدد المستخدمين"
      />
    </div>
    <div class="col-12 col-lg-4" v-if="stats.devices != undefined">
      <statistic-card-horizontal
        icon="UploadIcon"
        :statistic="stats.today_reports"
        statistic-title="التقارير المرفوعة اليوم"
        color="danger"
      />
    </div>
    <div class="col-12 col-lg-6">
      <b-card title="سجل الاجهزة">
        <div
          class="col-12 table-responsive"
          style="overflow-y: scroll; height: 600px"
        >
          <table class="table table-hover custom-table" table-striped a__v>
            <thead>
              <th>الوقت</th>
              <th>الجهاز</th>
              <th>المحتوى</th>
              <th>الاجراء</th>
            </thead>
            <tbody>
              <tr v-for="log in stats.logs" :key="log._id">
                <td>
                  {{ log.date }}
                </td>
                <td>
                  {{ log.serial_number }}
                </td>
                <td>
                  <span v-if="log.records.length > 1">
                    <textarea
                      name=""
                      class="form-control"
                      v-html="recordsValidate(log.records)"
                    ></textarea>
                  </span>
                  <span v-if="log.records.length == 1">
                    {{ log.records[0].id + " | " + log.records[0].time }}
                  </span>
                </td>
                <td>
                  {{ e(log.type) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </div>
    <div class="col-12 col-lg-6 g">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-times"></i>
            التقارير المرفوض رفعها
          </h4>
        </div>
        <div class="card-body">
          <div
            class="col-12 table-responsive"
            style="overflow-y: scroll; height: 600px"
          >
            <table class="table table-hover custom-table">
              <thead>
                <th>الجهاز</th>
                <th>وقت السيرفر</th>
                <th>توقيت الجهاز</th>
                <th>السبب</th>
              </thead>
              <tbody>
                <tr v-for="report in stats.rejected" :key="report._id">
                  <td>
                    {{ report.serial_number }}
                  </td>
                  <td>
                    {{ report.date }}
                  </td>
                  <td>
                    {{ report.time }}
                  </td>
                  <td>
                    {{ e("reason-" + report.reason) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { BCard, BCardText, BBadge, BLink, BImg } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";
import { BOverlay } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BBadge,
    BOverlay,
    AppEchartDoughnut,
    StatisticCardHorizontal,
  },
  data() {
    return {
      overlay: true,
      stats: {},
    };
  },
  created() {
    var g = this;
    $.post(api + "/stats", {
      token: token,
    })
      .then(function (e) {
        e = JSON.parse(e);
        g.stats = e;
        g.overlay = false;
      })
      .catch(function (e) {
        alert(e);
      });
  },
  methods: {
    e(d) {
      return e(d);
    },
    recordsValidate(arr) {
      var n = "";
      arr.forEach(function (a) {
        n = n + `${a.id} | ${a.time}\n`;
      });
      return n;
    },
  },
};
</script>

<style >
.a__v th,
.a__v tr,
.a__v td {
  padding: 5px !important;
}
</style>