<template>
  <b-overlay :show="overlay" class="row">
    <div class="col-12 g table-responsive">
      <div class="card card-body">
        <table class="table table-hover table-bordered custom-table">
          <thead>
            <th>#</th>
            <th>المستخدم</th>
            <th>تاريخ الانضمام</th>
            <th>الدولة</th>
            <th>الحالة</th>
            <th>خيارات</th>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user._id">
              <td>
                {{ user._id }}
              </td>
              <td>
                <b-link :to="'/users/overview/' + user._id">
                  {{ user.name }}
                  <i class="fa fa-arrow-left"></i>
                  <br />
                  <small>{{ user.phone }}</small></b-link
                >
              </td>
              <td>
                {{ user.joindate }}
              </td>
              <td>
                {{ user.country }}
              </td>
              <td>
                <span v-if="user.activated" class="badge bg-success">
                  فعال
                </span>
                <span v-if="!user.activated" class="badge bg-danger">
                  غير مفعل
                </span>
                <span v-if="!user.activated">
                  <br />
                  <small>{{ user.otp }}</small>
                </span>
              </td>
              <td>
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  text="خيارات"
                  variant="relief-secondary"
                >
                  <b-dropdown-item>
                    <a
                      @click="open(link + '/join/' + user.jwt + '/to/---')"
                      target="_blank"
                    >
                      الدخول بحسابه
                      <i class="fa fa-external-link"></i
                    ></a>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteUser(user._id)">
                    <i class="fa fa-trash text-danger"></i>
                    حذف المستخدم
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BBadge,
  BLink,
  BDropdown,
  BDropdownItem,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { BOverlay } from "bootstrap-vue";
var moment = require("moment-timezone");
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BBadge,
    BOverlay,
    BDropdown,
    BDropdownItem,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      overlay: true,
      users: [],
      link: link,
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    open(e) {
      window.open(e);
    },
    timeAgo(date, tz) {
      if (moment(date).tz(tz)) {
        return moment(date).lang(localStorage.getItem("lang")).tz(tz).fromNow();
      } else {
        return date;
      }
    },
    getUsers() {
      var g = this;
      g.overlay = true;
      $.post(api + "/users/list", {
        token: token,
      })
        .then(function (e) {
          e = JSON.parse(e);
          g.users = e;
          g.overlay = false;
        })
        .catch(function (e) {
          alert(e);
        });
    },
    deleteUser(id) {
      if (confirm("متأكد من حذف المستخدم؟")) {
        var g = this;
        g.overlay = true;
        $.post(api + "/users/delete", {
          token: token,
          id: id,
        })
          .then(function (e) {
            g.overlay = false;
            if (e == "OK") {
              alert("تم حذف المستخدم بنجاح");
            }
            g.getUsers();
          })
          .catch(function (e) {
            alert(e);
          });
      }
    },
  },
};
</script>
