<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      من برمجة شركة
      <b-link class="ml-25" href="https://brmja.com" target="_blank"
        >برمجة دوت كوم</b-link
      >
      <span class="d-none d-sm-inline-block">, جميع الحقوق محفوظة</span>
    </span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
