import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Varaibles

//
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
window.api0 = 'https://server.biokeep.brmja.com'
window.api = 'https://server.biokeep.brmja.com/admin'
window.token = localStorage.getItem('admin')
window.link = 'https://app.biokeep.brmja.com'

// window.api0 = 'http://127.0.0.1:3000'
// window.api = 'http://127.0.0.1:3000/admin'
// window.token = localStorage.getItem('admin')
// window.link = 'http://localhost:8081'

// 
window.timezones = [
  {
    ar: '[Asia/Riyadh] السعودية, الرياض',
    en: 'Asia/Riyadh'
  },
  {
    ar: '[Africa/Cairo] مصر, القاهرة',
    en: 'Africa/Cairo'
  },
]

// Language
window.e = (word) => {
  return word;
}
// End Language

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
