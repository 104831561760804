<template>
  <b-overlay :show="overlay" class="row">
    <div class="col-12 c g">
      <div class="card card-body">
        <div class="row">
          <div class="col-12 col-lg-3 g">
            <div class="form-group">
              <label for="">العنوان</label>
              <input
                type="text"
                class="form-control"
                v-model="filter.title"
                @change="getDevices()"
                placeholder="%..%"
              />
            </div>
          </div>
          <div class="col-12 col-lg-3 g">
            <div class="form-group">
              <label for="">Serial Number</label>
              <input
                type="text"
                class="form-control"
                v-model="filter.serial_number"
                @change="getDevices()"
                placeholder="%..%"
              />
            </div>
          </div>
          <div class="col-12 col-lg-3 g">
            <div class="form-group">
              <label for="">الحالة</label>
              <select
                name=""
                class="form-control"
                @change="getDevices()"
                v-model="filter.status"
                id=""
              >
                <option value="0">في انتظار التفعيل</option>
                <option value="1">فعال</option>
                <option value="2">متوقف</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-lg-3 g">
            <div class="form-group">
              <label for="">المستخدم</label>
              <select
                name=""
                class="form-control"
                @change="getDevices()"
                v-model="filter.user_id"
                id=""
              >
                <template v-for="user in users">
                  <option :value="user._id" :key="user._id">
                    {{ user.name }}
                  </option>
                </template>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 g table-responsive">
      <div class="card card-body">
        <table class="table table-hover table-bordered custom-table">
          <thead>
            <th>المستخدم</th>
            <th>العنوان</th>
            <th>SN</th>
            <th>الحالة</th>
            <th>الاتصال</th>
            <th>حالة الاشتراك</th>
            <th>تاريخ الاضافة</th>
            <th>خيارات</th>
          </thead>
          <tbody>
            <tr v-for="device in devices" :key="device._id">
              <td>
                <b-link :to="'/users/overview/' + device.user_id">
                  {{ getUser(device.user_id).name }}
                  <i class="fa fa-arrow-left"></i>
                  <br />
                  <small>{{ getUser(device.user_id).phone }}</small></b-link
                >
              </td>
              <td>
                {{ device.title }}
              </td>
              <td>
                {{ device.serial_number }}
              </td>
              <td>
                <span v-if="device.status == '1'" class="badge bg-success">
                  فعال
                </span>
                <span v-if="device.status == '2'" class="badge bg-danger">
                  متوقف
                </span>
                <span v-if="device.status == '0'" class="badge bg-warning">
                  في الانتظار
                </span>
              </td>
              <td>
                <span
                  v-if="device.last_update == null"
                  class="badge bg-warning"
                >
                  لم يتم الربط
                </span>
                <span v-if="device.last_update">
                  <span
                    v-if="
                      timeAgo(device.last_update, device.time_zone).includes(
                        'now'
                      ) ||
                      timeAgo(device.last_update, device.time_zone).includes(
                        'second'
                      ) ||
                      timeAgo(device.last_update, device.time_zone).includes(
                        'ثوان'
                      )
                    "
                    class="badge bg-success"
                  >
                    متصل الان
                  </span>
                  <span
                    v-if="
                      !timeAgo(device.last_update, device.time_zone).includes(
                        'now'
                      ) &&
                      !timeAgo(device.last_update, device.time_zone).includes(
                        'second'
                      ) &&
                      !timeAgo(device.last_update, device.time_zone).includes(
                        'ثوان'
                      )
                    "
                    class="badge bg-secondary"
                  >
                    اخر ظهور:
                    {{ timeAgo(device.last_update, device.time_zone) }}
                  </span>
                </span>
              </td>
              <td>
                <span v-if="device.demo == '1'" class="badge bg-danger">
                  تجريبي
                </span>
                تاريخ الانتهاء: {{ device.exdate }}
              </td>
              <td>
                {{ device.date.split("T")[0] }}
              </td>
              <td>
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  text="خيارات"
                  variant="relief-secondary"
                >
                  <b-dropdown-item>
                    <a
                      @click="
                        open(
                          link +
                            '/join/' +
                            getUser(device.user_id).jwt +
                            '/to/devices---overview---' +
                            device._id
                        )
                      "
                      target="_blank"
                    >
                      نظرة عامة
                      <i class="fa fa-external-link"></i>
                      <br />
                      <small>الدخول بحسابه</small></a
                    >
                  </b-dropdown-item>
                  <b-dropdown-item @click="editDevice(device)">
                    <i class="fa fa-edit text-success"></i>
                    تعديل الجهاز
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteDevice(device._id)">
                    <i class="fa fa-trash text-danger"></i>
                    حذف الجهاز
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تعديل الجهاز"
      ok-only
      ok-title="شكراً يسطا، احفظ"
    >
      <div>
        <div class="form-group">
          <label for="">العنوان</label>
          <input type="text" class="form-control" v-model="device.title" />
        </div>
        <div class="form-group">
          <label for="">SN</label>
          <input
            type="text"
            class="form-control"
            v-model="device.serial_number"
          />
        </div>
        <div class="form-group">
          <label for="">الحالة</label>
          <select name="" class="form-control" v-model="device.status" id="">
            <option value="0">في انتظار التفعيل</option>
            <option value="1">فعال</option>
            <option value="2">متوقف</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">تجريبي</label>
          <select name="" class="form-control" v-model="device.demo" id="">
            <option :value="true">نعم</option>
            <option :value="false">لا</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">تاريخ انتهاء الاشتراك</label>
          <input type="text" class="form-control" v-model="device.exdate" />
        </div>
        <div class="form-group">
          <label for="">المنطقة الزمنية</label>
          <input type="text" class="form-control" v-model="device.time_zone" />
        </div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BBadge,
  BLink,
  BDropdown,
  BDropdownItem,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { BOverlay } from "bootstrap-vue";
var moment = require("moment-timezone");
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BBadge,
    BOverlay,
    BDropdown,
    BDropdownItem,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      overlay: true,
      devices: [],
      filter: {},
      users: [],
      link: link,
      device: {},
    };
  },
  created() {
    this.getDevices();
  },
  methods: {
    open(e) {
      window.open(e);
    },
    timeAgo(date, tz) {
      if (moment(date).tz(tz)) {
        return moment(date).lang(localStorage.getItem("lang")).tz(tz).fromNow();
      } else {
        return date;
      }
    },
    getUser(id) {
      var u = {};
      this.users.forEach((element) => {
        if (element._id == id) {
          u = element;
        }
      });
      return u;
    },
    getDevices() {
      var g = this;
      g.overlay = true;
      $.post(api + "/devices/list", {
        token: token,
        filter: this.filter,
      })
        .then(function (e) {
          e = JSON.parse(e);
          g.devices = e.devices;
          g.users = e.users;
          g.overlay = false;
        })
        .catch(function (e) {
          alert(e);
        });
    },
    deleteDevice(id) {
      if (
        confirm(
          "متأكد من حذف الجهاز نهائياً؟ ربما يؤثر بشكل كبير على التقارير السابقة."
        )
      ) {
        var g = this;
        g.overlay = true;
        $.post(api + "/devices/delete", {
          token: token,
          id: id,
        })
          .then(function (e) {
            g.overlay = false;
            if (e == "OK") {
              alert("تم حذف الجهاز بنجاح");
            }
            g.getDevices();
          })
          .catch(function (e) {
            alert(e);
          });
      }
    },
    editDevice(device) {
      this.device = device;
      this.$bvModal.show("modal-1");
      var g = this;
      setTimeout(() => {
        $("#modal-1___BV_modal_footer_ .btn").click(function () {
          g.overlay = true;
          $.post(api + "/devices/edit", {
            token: token,
            id: g.device._id,
            device: g.device,
          })
            .then(function (e) {
              if (e == "OK") {
                g.overlay = false;
                g.getDevices();
                alert("تم تعديل الجهاز بنجاح");
              }
            })
            .catch(function (e) {
              alert(e);
            });
        });
      }, 500);
    },
  },
};
</script>
