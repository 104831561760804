var arr = [{
  title: 'الرئيسية',
  route: 'home',
  icon: 'HomeIcon',
}, {
  title: 'أجهزة البصمة',
  route: 'devices',
  icon: 'ServerIcon',
}, {
  title: 'طلبات الدفع <span class=\'orders-count\'></span>',
  route: 'orders',
  icon: 'DollarSignIcon',
}, {
  title: 'المستخدمين',
  route: 'users',
  icon: 'UsersIcon',
}, {
  title: 'الصفحات الديناميكية',
  route: 'options',
  icon: 'FileIcon',
}, {
  title: 'تسجيل الخروج',
  route: 'logout',
  icon: 'LogOutIcon',
},]

export default arr