<template>
  <div class="row">
    <div class="col-12 c g">
      <div class="card">
        <div class="card-header">
          <h4>طرق الدفع</h4>
        </div>
        <div class="card-body">
          <quill-editor v-model="payments" />
        </div>
        <div class="card-footer">
          <button class="btn btn-relief-success" @click="savePayments()">
            <i class="fa fa-save"></i>
            حفظ
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 c g">
      <div class="card">
        <div class="card-header">
          <h4>API</h4>
        </div>
        <div class="card-body">
          <quill-editor v-model="api" />
        </div>
        <div class="card-footer">
          <button class="btn btn-relief-success" @click="saveAPI()">
            <i class="fa fa-save"></i>
            حفظ
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 c g">
      <div class="card">
        <div class="card-header">
          <h4>شروحات الاستخدام</h4>
        </div>
        <div class="card-body">
          <quill-editor v-model="docs" />
        </div>
        <div class="card-footer">
          <button class="btn btn-relief-success" @click="savedocs()">
            <i class="fa fa-save"></i>
            حفظ
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 c g">
      <div class="card">
        <div class="card-header">
          <h4>خدمات أخرى</h4>
        </div>
        <div class="card-body">
          <quill-editor v-model="extra" />
        </div>
        <div class="card-footer">
          <button class="btn btn-relief-success" @click="saveextra()">
            <i class="fa fa-save"></i>
            حفظ
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 c g">
      <div class="card">
        <div class="card-header">
          <h4>الدعم الفني</h4>
        </div>
        <div class="card-body">
          <quill-editor v-model="support" />
        </div>
        <div class="card-footer">
          <button class="btn btn-relief-success" @click="savesupport()">
            <i class="fa fa-save"></i>
            حفظ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data: () => ({
    payments: ``,
    api: ``,
    docs: ``,
    extra: ``,
    support: "",
  }),
  created() {
    var g = this;
    $.post(api0 + "/options")
      .then(function (r) {
        r = JSON.parse(r);
        g.payments = r.payments;
        g.api = r.api;
        g.docs = r.docs;
        g.extra = r.extra;
        g.support = r.support;
      })
      .catch(function (r) {
        alert(r);
      });
  },
  methods: {
    savePayments() {
      var g = this;
      $.post(api + "/options", {
        token: token,
        type: "payments",
        content: this.payments,
      })
        .then(function (r) {
          alert("تم الحفظ");
        })
        .catch(function (r) {
          alert(r);
        });
    },
    saveAPI() {
      var g = this;
      $.post(api + "/options", {
        token: token,
        type: "api",
        content: this.api,
      })
        .then(function (r) {
          alert("تم الحفظ");
        })
        .catch(function (r) {
          alert(r);
        });
    },
    savedocs() {
      var g = this;
      $.post(api + "/options", {
        token: token,
        type: "docs",
        content: this.docs,
      })
        .then(function (r) {
          alert("تم الحفظ");
        })
        .catch(function (r) {
          alert(r);
        });
    },
    saveextra() {
      var g = this;
      $.post(api + "/options", {
        token: token,
        type: "extra",
        content: this.extra,
      })
        .then(function (r) {
          alert("تم الحفظ");
        })
        .catch(function (r) {
          alert(r);
        });
    },
    savesupport() {
      var g = this;
      $.post(api + "/options", {
        token: token,
        type: "support",
        content: this.support,
      })
        .then(function (r) {
          alert("تم الحفظ");
        })
        .catch(function (r) {
          alert(r);
        });
    },
  },
};
</script>