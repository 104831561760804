<template>
  <b-overlay :show="overlay" class="row">
    <div class="col-12 g table-responsive">
      <div class="row">
        <div class="col-12 g">
          <button class="btn btn-success" @click="onlySuccess()">
            <i class="fa fa-check"></i>
            الناجحة فقط
          </button>
          &nbsp;&nbsp;
          <button class="btn btn-secondary" @click="toExcel()">
            <i class="fa fa-download"></i>
            تصدير المعروض
          </button>
        </div>
      </div>
      <div class="card card-body">
        <table class="table table-hover table-bordered custom-table">
          <thead>
            <th>المستخدم</th>
            <th>رقم الطلب</th>
            <th>التاريح</th>
            <th>المبلغ</th>
            <th>تفاصيل</th>
            <th>الأجهزة</th>
            <th>الحالة</th>
            <th>خيارات</th>
          </thead>
          <tbody>
            <tr v-for="order in orders" :key="order._id">
              <td>
                <b-link :to="'/users/overview/' + order.user_id">
                  {{ getUser(order.user_id).name }}
                  <i class="fa fa-arrow-left"></i>
                  <br />
                  <small>{{ getUser(order.user_id).phone }}</small></b-link
                >
              </td>
              <td>
                {{ order.order_id }}
              </td>
              <td>
                {{ order.date }}
              </td>
              <td>
                {{ order.amount }}
                <br />
                المستخدمين: {{ order.limit }}
              </td>
              <td>
                {{ order.notes }}
                <span v-if="order.file">
                  <br />
                  <a :href="order.file" v-if="order.file" target="_blank"
                    ><i class="fa fa-file"></i> File</a
                  ></span
                >
              </td>
              <td>
                {{ order.devices.join() }}
              </td>
              <td>
                <span v-if="order.status == '1'" class="badge bg-success">
                  موافق عليه
                </span>
                <span v-if="order.status == '2'" class="badge bg-danger">
                  مرفوض
                </span>
                <span v-if="order.status == '0'" class="badge bg-warning">
                  في الانتظار
                </span>
                <span class="g" v-if="order.status == '2'">
                  السبب: {{ order.admin_notes }}
                </span>
              </td>
              <td>
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  text="خيارات"
                  variant="relief-secondary"
                >
                  <b-dropdown-item>
                    <a
                      @click="
                        open(
                          link +
                            '/join/' +
                            getUser(order.user_id).jwt +
                            '/to/pay'
                        )
                      "
                      target="_blank"
                    >
                      نظرة عامة
                      <i class="fa fa-external-link"></i>
                      <br />
                      <small>الدخول بحسابه</small></a
                    >
                  </b-dropdown-item>
                  <b-dropdown-item @click="editOrder(order)">
                    <i class="fa fa-edit text-success"></i>
                    تعديل الطلب
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteOrder(order._id)">
                    <i class="fa fa-trash text-danger"></i>
                    حذف الطلب
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تعديل الطلب"
      ok-only
      ok-title="شكراً يسطا، احفظ"
    >
      <div>
        <div class="form-group">
          <label for="">رقم الطلب</label>
          <input
            type="text"
            readonly
            class="form-control"
            v-model="order.order_id"
          />
        </div>
        <div class="form-group">
          <label for="">الحالة</label>
          <select name="" class="form-control" v-model="order.status" id="">
            <option value="0">في انتظار</option>
            <option value="1">فعال</option>
            <option value="2">مرفوض</option>
          </select>
        </div>
        <span class="g" v-if="order.status == '1'">
          <div class="alert alert-success">
            <h4>سيتم تعديل حالة الاجهزة لتاريخ الانتهاء التالي:</h4>
          </div>
        </span>
        <div class="form-group">
          <label for=""
            >عدد المستخدمين المسموح به (اذا كان الطلب للتطبيق)</label
          >
          <input type="number" class="form-control" v-model="order.limit" />
        </div>
        <div class="form-group" v-if="order.status == 2">
          <label for="">سبب الرفض</label>
          <textarea
            class="form-control"
            v-model="order.admin_notes"
            name=""
            id=""
          ></textarea>
        </div>
        <div class="form-group">
          <label for="">تاريخ انتهاء الاشتراك</label>
          <input type="text" class="form-control" v-model="order.exdate" />
        </div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import { jsontoexcel } from "vue-table-to-excel";
import {
  BCard,
  BCardText,
  BBadge,
  BLink,
  BDropdown,
  BDropdownItem,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { BOverlay } from "bootstrap-vue";
var moment = require("moment-timezone");
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BBadge,
    BOverlay,
    BDropdown,
    BDropdownItem,
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      json: {
        head: ["order id", "user", "date", "amount", "status", "file"],
        fileName:
          "biokeep" +
          "-" +
          new Date().toISOString().split("T")[0] +
          "-" +
          ".csv",
      },
      overlay: true,
      orders: [],
      users: [],
      link: link,
      order: {},
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    open(e) {
      window.open(e);
    },
    timeAgo(date, tz) {
      if (moment(date).tz(tz)) {
        return moment(date).lang(localStorage.getItem("lang")).tz(tz).fromNow();
      } else {
        return date;
      }
    },
    getUser(id) {
      var u = {};
      this.users.forEach((element) => {
        if (element._id == id) {
          u = element;
        }
      });
      return u;
    },
    getOrders() {
      var g = this;
      g.overlay = true;
      $.post(api + "/orders/list", {
        token: token,
      })
        .then(function (e) {
          e = JSON.parse(e);
          g.orders = e.orders;
          g.users = e.users;
          g.overlay = false;
        })
        .catch(function (e) {
          alert(e);
        });
    },
    deleteOrder(id) {
      if (confirm("متأكد من حذف الطلب؟")) {
        var g = this;
        g.overlay = true;
        $.post(api + "/orders/delete", {
          token: token,
          id: id,
        })
          .then(function (e) {
            g.overlay = false;
            if (e == "OK") {
              alert("تم حذف الطلب بنجاح");
            }
            g.getOrders();
          })
          .catch(function (e) {
            alert(e);
          });
      }
    },
    editOrder(order) {
      this.order = order;
      this.$bvModal.show("modal-1");
      var g = this;
      setTimeout(() => {
        $("#modal-1___BV_modal_footer_ .btn").click(function () {
          g.overlay = true;
          $.post(api + "/orders/edit", {
            token: token,
            id: g.order._id,
            order: g.order,
          })
            .then(function (e) {
              if (e == "OK") {
                g.overlay = false;
                g.getOrders();
                alert("تم تعديل الطلب بنجاح");
              }
            })
            .catch(function (e) {
              alert(e);
            });
        });
      }, 500);
    },
    onlySuccess() {
      var g = this,
        newarr = [];
      g.orders.forEach(function (a) {
        if (a.status == "1") {
          newarr.push(a);
        }
      });
      g.orders = newarr;
    },
    toExcel() {
      const { head, fileName } = this.json;
      // "order id", "user", "date", "amount", "status", "file"
      var v = this.orders.map((x) => {
        return {
          order_id: x.order_id,
          user_id: x.user_id,
          date: x.date,
          amount: x.amount,
          status: x.status,
          file: x.file,
        };
      });
      jsontoexcel.getXlsx(v, head, fileName);
    },
  },
};
</script>
