import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/views/devices/index.vue')
    },
    {
      path: '/options',
      name: 'options',
      component: () => import('@/views/general/options.vue')
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders/index.vue')
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/index.vue')
    },
    {
      path: '/users/overview/:od',
      name: 'usersOrderview',
      component: () => import('@/views/users/overview.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/auth/logout.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
